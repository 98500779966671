import BootstrapTable from 'react-bootstrap-table-next';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from "react-router-dom";

const FeatureTable = () => {
    const [bgImg, setBgImg] = useState({
        backgroundColor: 'linear-gradient(to bottom, #2cc6ff 0, #21ecff 100%)'
    });

    const [temp, setTemp] = useState(null);
    const color = localStorage.getItem("color");
    const history = useHistory();

    useEffect(() => {
        setTemp(color);
        if (color === "color-1")
            setBgImg({
                backgroundColor: 'linear-gradient(to bottom, #2cc6ff 0, #21ecff 100%)'
            });
        else if (color === "color-2")
            setBgImg({
                backgroundColor: 'linear-gradient(to bottom, #1bee90 0, #14e296 99%)'
            });
        else
            setBgImg({
                backgroundColor: 'linear-gradient(to bottom, #2cc6ff 0, #21ecff 100%)'
            });
    }, [color]);

    const products = [
        { id: 0, name: "User Management", price: true, col3: true },
        { id: 1, name: "Property Management", price: true, col3: true },
        { id: 3, name: "Lease Management", price: true, col3: true },
        { id: 5, name: "Notification", price: true, col3: true },
        { id: 4, name: "Account Management", price: true, col3: true },
        { id: 5, name: "End Client Access and on boarding", price: true, col3: true },
        { id: 5, name: "Case Management", price: false, col3: true },
        { id: 6, name: "Vendor On Boarding", price: false, col3: true },
        { id: 6, name: "Reports & Dashboard", price: false, col3: true }
    ];

    const columns = [
        { dataField: "id", hidden: true },
        {
            dataField: "name",
            formatter: (cell, row) => {
                return <p className="featuresTableElemet">{cell}</p>;
            },
            headerAttrs: { hidden: true }
        },
        {
            dataField: "price",
            classes: 'demo-key-row',
            formatter: (cell, row) => {
                return cell ? <i className="fa fa-check fa-2x" aria-hidden="true" id="greenColor"></i> : null;
            },
            headerAttrs: { hidden: true }
        },
        {
            dataField: "col3",
            classes: 'demo-key-row',
            formatter: (cell, row) => {
                return cell ? <i className="fa fa-check fa-2x" aria-hidden="true" id="greenColor"></i> : null;
            },
            headerAttrs: { hidden: true }
        }
    ];

    const CaptionElement = () => <h3 className="TableHeading" style={bgImg}>Residential Features</h3>;

    const defaultSorted = [
        { dataField: "name", order: "aesc" }
    ];

    return (
        <>
            <section id="feature" className="feature">
                <div className="container">
                    <div className="Table">
                        <div className="row text-center margin-fot">
                            <h1 className="fontforHeading2" id="lowecase">Make sure that your property management software enables you to do more than just manage properties.</h1>
                        </div>
                        {/* <Row>
                            <Col sm={4} md={4} lg={4} xs={4} xl={4}>
                                <div className="row marginTopBottom">
                                    <a
                                        className="btn btn-custom theme-color margin-left "
                                        id="marginleftforweb"
                                        role="button"
                                        onClick={() => { history.push("/riseAndNestFeatures") }}
                                    >
                                        Learn More {'  >'}
                                    </a>
                                </div>
                            </Col>
                            <Col sm={4} md={4} lg={4} xs={4} xl={4} className="paddingleftBy10">
                                <h1 className="newcsssforplan fontforHeading4" id="lowecase">Rise & Nest Limited</h1>
                                <p className="marginBottomZerom fourteenPixelFontSizw"> Starting at</p>
                                <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw"> $ 45 per month</p>
                                <p className="marginBottomZerom fourteenPixelFontSizw">Upto 15 Users</p>
                                <p className="marginBottomZerom fontforHeadingg"> Additional $ 2 charge per user</p>
                                <Link
                                    className="fontSizeHandline fourteenPixelFontSizw"
                                    to={`${process.env.PUBLIC_URL}/riseandNestLimitedPlan`}
                                >
                                    See More
                                </Link>
                            </Col>
                            <Col sm={4} md={4} lg={4} xs={4} xl={4} className="paddingleftBy10">
                                <h1 className="newcsssforplan fontforHeading4" id="lowecase">Rise & Nest Unlimited</h1>
                                <p className="marginBottomZerom fourteenPixelFontSizw"> Starting at</p>
                                <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw"> $ 200 per month</p>
                                <p className="marginBottomZerom fourteenPixelFontSizw">Upto 100 Users</p>
                                <p className="marginBottomZerom fontforHeadingg"> Additional $ 2 charge per user</p>
                                <Link
                                    className="fontSizeHandline fontSizeHandline fourteenPixelFontSizw"
                                    to={`${process.env.PUBLIC_URL}/riseAndNestUnlimitedPlan`}
                                >
                                    See More
                                </Link>
                            </Col>
                        </Row> */}

                        {/*  new pricing in table format */}
                        {/* <Row>
                            <Col sm={4} md={4} lg={4} xs={4} xl={4}>
                                <div className="row marginTopBottom">
                                    <a
                                        className="btn btn-custom theme-color margin-left "
                                        id="marginleftforweb"
                                        role="button"
                                        onClick={() => { history.push("/riseAndNestFeatures") }}
                                    >
                                        Learn More {'  >'}
                                    </a>
                                </div>
                            </Col>
                            <Col>
                                <h3>Category</h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Features</th>
                                            <th>Listing Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p className="marginBottomZerom fourteenPixelFontSizw fontforHeadingg">Essential</p>
                                            </td>
                                            <td>
                                                <p>
                                                    {"> "}Restriction on 200 properties<br />
                                                    {"> "}Standard support <br />
                                                    <span style={{ paddingLeft: '20px' }}>$799/month</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="marginBottomZerom fourteenPixelFontSizw fontforHeadingg">Elevate</p>
                                            </td>
                                            <td>
                                                <p>
                                                    {"> "}Restriction on 500 properties<br />
                                                    {"> "}24*7 Customer Support<br />
                                                    <span style={{ paddingLeft: '20px' }}>$1199/month</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="marginBottomZerom fourteenPixelFontSizw fontforHeadingg">Zenith</p>
                                            </td>
                                            <td>
                                                <p>
                                                    {"> "}No restriction on properties<br />
                                                    {"> "}24*7 Customer Support<br />
                                                    {"> "}Assistance on all <br />
                                                    <span style={{ paddingLeft: '20px' }}>documentation, proof reading</span>
                                                    <span style={{ paddingLeft: '20px' }}> and filing $1699/month</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p className="marginBottomZerom fourteenPixelFontSizw fontforHeadingg">Professional Services</p>
                                            </td>
                                            <td>
                                                <p>
                                                    {"> "}Customize App as per customer<br />
                                                    <span style={{ paddingLeft: '20px' }}>wish</span><br/>
                                                    <span style={{ paddingLeft: '20px' }}>Start with $ 1000 (Onetime)</span>
                                                </p>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </Col>
                        </Row> */}

                        {/*  new pricing in list format */}
                        {/* <Row>
                        
                            <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                                <h1 className="newcsssforplan fontforHeading4" id="lowecase">Essential</h1>
                                <p className="marginBottomZerom fourteenPixelFontSizw">{">"}Restriction on 200 properties</p>
                                <p className="marginBottomZerom fourteenPixelFontSizw">{">"}Standard support</p>
                                <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">$799/month</p>
                            </Col>
                            <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                                <h1 className="newcsssforplan fontforHeading4" id="lowecase">Elevate</h1>
                                <p className="marginBottomZerom fourteenPixelFontSizw">{">"}Restriction on 500 properties</p>
                                <p className="marginBottomZerom fourteenPixelFontSizw">{">"}24*7 Customer Support</p>
                                <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">$1199/month</p>
                            </Col>
                            <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                                <h1 className="newcsssforplan fontforHeading4" id="lowecase">Zenith</h1>
                                <p className="marginBottomZerom fourteenPixelFontSizw">{">"}No restriction on properties</p>
                                <p className="marginBottomZerom fourteenPixelFontSizw">{">"}24*7 Customer Support</p>
                                <p className="marginBottomZerom fourteenPixelFontSizw">{">"}Assistance on all documentation, proof reading and filing</p>
                                <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">$1699/month</p>
                            </Col>
                            <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                                <h1 className="newcsssforplan fontforHeading4" id="lowecase">Professional Services</h1>
                                <p className="marginBottomZerom fourteenPixelFontSizw">Customize App as per customer wish</p>
                                <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">Start with $1000 (Onetime)</p>
                            </Col>
                            <Col sm={12} md={12} lg={12} xs={12} xl={12} className="paddingleftBy10 marginBottom10">
                                <div className="row marginTopBottom">
                                    <a
                                        className="btn btn-custom theme-color margin-left "
                                        id="marginleftforweb"
                                        role="button"
                                        onClick={() => { history.push("/riseAndNestFeatures") }}
                                    >
                                        Learn More {'  >'}
                                    </a>
                                </div>
                            </Col>
                        </Row> */}

<Row>
                        
                        <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                            <h1 className="newcsssforplan fontforHeading4 color-grey" id="lowecase">Essential</h1>
                            <p className="marginBottomZerom fourteenPixelFontSizw color-black">{">"}Restriction on 200 properties</p>
                            <p className="marginBottomZerom fourteenPixelFontSizw">{">"}Standard support</p>
                            <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">$799/month</p>
                        </Col>
                        <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                            <h1 className="newcsssforplan fontforHeading4 color-grey" id="lowecase">Elevate</h1>
                            <p className="marginBottomZerom fourteenPixelFontSizw color-black">{">"}Restriction on 500 properties</p>
                            <p className="marginBottomZerom fourteenPixelFontSizw color-black">{">"}24*7 Customer Support</p>
                            <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">$1199/month</p>
                        </Col>
                        <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                            <h1 className="newcsssforplan fontforHeading4 color-grey" id="lowecase">Zenith</h1>
                            <p className="marginBottomZerom fourteenPixelFontSizw color-black">{">"}No restriction on properties</p>
                            <p className="marginBottomZerom fourteenPixelFontSizw color-black">{">"}24*7 Customer Support</p>
                            <p className="marginBottomZerom fourteenPixelFontSizw color-black">{">"}Assistance on all documentation, proof reading and filing</p>
                            <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">$1699/month</p>
                        </Col>
                        <Col sm={12} md={6} lg={3} xs={12} xl={3} className="paddingleftBy10 marginBottom10">
                            <h1 className="newcsssforplan fontforHeading4 color-grey" id="lowecase">Professional Services</h1>
                            <p className="marginBottomZerom fourteenPixelFontSizw color-black">Customize App as per customer wish</p>
                            <p className="marginBottomZerom pricingConetnt fourteenPixelFontSizw">Start with $1000 (Onetime)</p>
                        </Col>
                        <Col sm={12} md={12} lg={12} xs={12} xl={12} className="paddingleftBy10 marginBottom10">
                            <div className="row marginTopBottom">
                                <a
                                    className="btn btn-custom theme-color margin-left "
                                    id="marginleftforweb"
                                    role="button"
                                    onClick={() => { history.push("/riseAndNestFeatures") }}
                                >
                                    Learn More {'  >'}
                                </a>
                            </div>
                        </Col>
                    </Row>

                        <CaptionElement />
                        <BootstrapTable
                            keyField="id"
                            data={products}
                            columns={columns}
                            headerClasses="header-class"
                            condensed
                            defaultSorted={defaultSorted}
                        />
                    </div>
                </div>
            </section>
        </>
    )

}

export default FeatureTable;
