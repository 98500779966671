import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Slider from "react-slick";
import "./Properties.css";

const Modules = () => {
    const history = useHistory();

    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)`,
    });

    const color = localStorage.getItem("color");

    useEffect(() => {
        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)`,
            });
        else if (color === "color-2")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/1.png)`,
            });
        else
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/1.png)`,
            });
    }, [color]);

    // Slick slider Options
    const options = {
        arrows: false,
        accessibility: false,
        infinite: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        dotClass: ".blog-carousel",
        margin: 30,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    infinite: true,
                },
            },
        ],
    };

    // Dynamic Blog Data Easy to Update
    let data = [
        {
            title: "Accounting",
            link: "blog-details",
            date: "28 December 2017",
            photo: `${process.env.PUBLIC_URL}/assets/images/app/Accounting1.png`,
            discription: "No need of separate book-Keeping of accounting.",
            hits: "10",
            comments: "15",
        },
        {
            title: "Lease Management",
            link: "blog-details",
            date: "15 jun 2016",
            photo: `${process.env.PUBLIC_URL}/assets/images/app/Application3.png`,
            discription: "Lease management has been simplified.",
            hits: "15",
            comments: "30",
        },
        {
            title: "User Management ",
            link: "blog-details",
            date: "10 February 2012",
            photo: `${process.env.PUBLIC_URL}/assets/images/app/User1.png`,
            discription: "Maintains the manager's ability to allow / deny users access.",
            hits: "6",
            comments: "8",
        },
        {
            title: "Property Management ",
            link: "blog-details",
            date: "12 march 2015",
            photo: `${process.env.PUBLIC_URL}/assets/images/app/HomeLogo2.png`,
            discription: "Manage all your properties from anywhere.",
            hits: "10",
            comments: "50",
        },
        {
            title: "Case Management",
            link: "blog-details",
            date: "12 march 2015",
            photo: `${process.env.PUBLIC_URL}/assets/images/app/Maintenance3.png`,
            discription: "Online case Management request processing.",
            hits: "10",
            comments: "50",
        },
    ];

    // Dynamic Blog Data Loop
    let DataList = data.map((val, i) => {
        return (
            <div className="" key={i}>
                <div className="blog-block2">
                    <div className="blog-box2">
                        <p className="text-center fontheadingSize">{val.title}</p>
                        <div className="team-box2">
                            <div className="team-avtar">
                                <img src={val.photo} alt="" />
                            </div>
                        </div>
                        <p className="text-center caption-about2 ">{val.discription}</p>
                    </div>
                </div>
                <div className="">
                    {/* <h6>{val.date}</h6> */}
                    {/* <a href={val.link}>
            <h3>{val.title}</h3>
          </a> */}
                    {/* <h5>
            posted by {val.username}, {val.hits} hits, {val.comments} comment
          </h5> */}
                </div>
            </div>
        );
    });

    return (
        <section
            id="Module"
            className=""
            style={bgImg}
        >
            <div className="about-decor"></div>
            <div className="container">
                <div className="row ">
                    <div className="col-md-12 col-12 col-sm-12 col-lg-12 text-center">
                        <h1 className="fontforHeading2 fontcolor">We Provide Following Modules </h1>
                        <p className="caption-about2">using this you can manage your properties <br /></p>
                    </div>
                    <div className="col-sm-12">
                        <div className="">
                            <Slider
                                className="blog-carousel owl-carousel owl-theme rounded-dots slick-margin"
                                {...options}
                            >
                                {DataList}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Modules;
