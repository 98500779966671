import { createBrowserHistory } from 'history';
import React, { useEffect, useState, useRef } from "react";
import Menu from "./Navbar";
import '../css/ContactUs.css';
import { useHistory } from "react-router-dom";
// import emailjs from 'emailjs-com';
import emailjs from '@emailjs/browser';
// import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { init } from 'emailjs-com';
// import history from "../history";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FloatingLabel from "react-bootstrap-floating-label";
import Swal from "sweetalert2";
import Footer from "./Footer";
import { Helmet } from "react-helmet";






const ContactUs = (props) => {
    const [landingPageData, setLandingPageData] = useState({})

    const [FullName, setFullName] = useState("");
    const [Email, setEmail] = useState("");
    const [country, setCountry] = useState("");

    const [phoneNumber, setPhoneNumber] = useState("");
    const [comments, setComments] = useState("");
    const [error, seterror] = useState("");
    const [nameError, setNameError] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [countryerror, setCountryError] = useState("");
    const [phoneNumberErr, setPhoneNumberErr] = useState("");

    const [showPwd, setShowPwd] = useState(false);
    const history = useHistory();

    const form=useRef();



    // useEffect(() => {
    //   setLandingPageData(JsonData)
    // }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    const sendToComingSoon = (e) => {

        //   history.push("/thank-you")
        // return  <Redirect to='/coming-soon' />



    }

    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home2/home2-slider.png)`
    });

    const color = localStorage.getItem("color");

    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home2/home2-slider.png)`
            });
        else if (color === "color-2")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/Homev2.png)`
            })
        else
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/Homev2.png)`
            })
    }, [color]);


 
    const secondEmail = () => {
        // alert("here inside second email")
        Email.send({
            Host: "smtp.gmail.com",
            Username: "riseandnest@gmail.com",
            Password: "Pletratech7ujm&UJM",
            To: 'ketan.kumbhar@pletratech.com',
            From: "riseandnest@gmail.com",
            Subject: "Demo Request using plain mail sent",
            Body: "And this is the body"
        }).then(
            message => alert(message)
        );
    }



    const Validate = () => {
        let validEmail = false;
        let validCountry = false;
        let validName = false;
        let validPhoneNumber = false;

        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(Email)) {

            validEmail = true;

        }
        else {
            setEmailError("Your Email is not valid")

        }
        if (FullName.trim().split(" ").length >= 2 && FullName.split(" ").length < 3) {

            validName = true;

        }
        else {
            setNameError("Your name is not valid")


        }

        if (country.trim().length == 0) {
            setCountryError("Enter your country")
        }
        else {
            validCountry = true;
        }
        if (phoneNumber.length >= 8 && phoneNumber.length <= 14) {
            validPhoneNumber = true;
        }
        else {
            setPhoneNumberErr("Enter Valid Phone Number")
        }


        if (validEmail && validCountry && validName && validPhoneNumber) {
            return true;
        }
        else {
            var message = " You have entered ";
            if (validName == false) {
                message = message + " invalid name"
            }
            if (validEmail == false) {
                message = message + " invalid email"
            }
            if (validCountry == false) {
                message = message + " invalid country"
            }

            if (validPhoneNumber == false) {
                message = message + " invalid phone number"
            }

            Swal.fire({ icon: 'error', text: message });
            return false;
        }

    }


    const send = () => {
        let validate = Validate();
        if (validate == true) {
            sendEmail();
            secondEmail();
        }

    }



    const setBlankValue = () => {

        setEmail("");
        setPhoneNumber("");
        setCountry("");
        setComments("");
        setFullName("");
    }


    const sendEmail = (e) => {
        e.preventDefault();
        let validate = Validate();
        if (validate == true) {

                        const serviceID = 'service_bnn0kam';
                        const templateID = 'template_jqenhra';
                        const publicKey = 'iF9UOGKfQhsTciWxq';

                emailjs.sendForm(serviceID,templateID,".theme-form",publicKey)
                .then((result) => {

                    console.log(result.text);
                }).catch((error) => {
                    
                    console.log(error.text);
                });


            setBlankValue();
            Swal.fire("Request Submited", "Your demo request is submitted. Our contact person will get in touch with you shortly.", "success");
            history.push("/contactus")
        }

    }


    return (
        <>
            <div className="backgoundImg">
                <Menu homePage="home-three" />
                <Helmet>
                    <title>Contact Us - Property Management Software | Rise & Nest</title>

                    <meta name="description" content="Contact Rise & Nest at +1 201-254-5116, Monday-Friday, 9 am to 8 pm. After hours emergency? Email us anytime of day and we’ll get right back to you." />

                    <meta name="keywords" content="Property Management Software, Rental Property Management Software, Cloud Property Management Software, Rental Property Management System, Best Crm For Property Management, Cloud-based Real Estate Management Software, Landlord Management Software, Lease Management Software, Property Management Portal Software, Tenant Management System, Landlord Management Software" />
                    <link href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
                        rel="stylesheet" />


                </Helmet>
                <div className="container" id="marginTopForContactUs" >

                    <Row>

                        {/* <Fade left> */}
                        <Col sm={12} md={12} lg={8} xs={12} xl={8}>
                            <div className="content margintopby4">
                                <Row>

                                    <h1 id="DemoHeading" className=""> Request For a Free Demo</h1>

                                </Row>
                                <Row>
                                    <h3>We'd delighted to show you how Rise & Nest can help you manage your properties more efficiently while saving you time and money.</h3>

                                </Row>
                                <Row>
                                    <p>Fill out the form on this page, and we'll contact you to arrange a customised demo tailored to your specific business requirement.</p>
                                </Row>
                                {/* <Row>
                                        <p>Alternatively, call us at (800) 866-1144.</p>
                                    </Row> */}

                            </div>






                        </Col>

                        <Col className="card margintopby4" id="backgroundForCard">



                            <h1 id="DemoHeading1" className="text-center"> Get Demo</h1>




                            <div className="">
                                <form className="theme-form" autoComplete="off">
                                    <div className="form-group">
                                        <div className="md-fgrup-margin">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Name & Surname *"
                                                required="required"
                                                value={FullName}
                                                id="FullName"
                                                name="FullName"
                                                onChange={(e) => {
                                                    setFullName(e.target.value)
                                                    setNameError("")
                                                }}
                                                autoComplete="off"
                                            />
                                            {nameError != "" ? <span className="classofInvalid"> Invalid name </span> : null}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email Address *"
                                            required="required"
                                            value={Email}
                                            id="Email"
                                            name="Email"
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                                setEmailError("")
                                            }}
                                            autoComplete="off"
                                        />
                                        {emailerror != "" ? <span className="classofInvalid"> Invalid email id </span> : null}
                                    </div>

                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Country *"
                                            required="required"
                                            value={country}
                                            id="country"
                                            name="country"
                                            onChange={(e) => {
                                                setCountry(e.target.value)
                                                setCountryError("")
                                            }}
                                            autoComplete="off"
                                        />
                                        {countryerror != "" ? <span className="classofInvalid"> Invalid Country</span> : null}
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="Phone Number *"
                                            required="required"
                                            value={phoneNumber}
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            onChange={(e) => {
                                                setPhoneNumber(e.target.value)
                                                setPhoneNumberErr("")
                                            }}
                                            autoComplete="off"
                                        />
                                        {phoneNumberErr != "" ? <span className="classofInvalid"> Invalid Phone Number</span> : null}
                                    </div>


                                    {/* <TextareaPage/> */}
                                    <label htmlFor="exampleFormControlTextarea1">
                                        { }
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="comments"
                                        rows="3"
                                        placeholder="Questions / Comments "
                                        value={comments}
                                        name="comments"
                                        onChange={(e) => setComments(e.target.value)}
                                    />



                                    <div className="form-button text-center marginBottomForSignUp">
                                        <button type="submit" className="btn btn-custom theme-color" onClick={(e)=>sendEmail(e)}>
                                            Submit
                                        </button>
                                    </div>



                                </form>
                            </div>

                        </Col>
                        {/* </Fade>

                    <Fade right> */}

                        {/* </Fade> */}
                    </Row>


                </div>
            </div>
            <Footer />

        </>
    )
}


const TextareaPage = () => {
    return (
        <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">

            </label>
            <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="Questions / Comments "
            />
        </div>
    )
}

export default ContactUs;
