import { React, useEffect } from "react";
import Features1 from "./Features1";
import PriceUnlimited from "./PriceUnlimited";
import Menu from "./Navbar";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const AllRiseAndNestFeatures = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Menu blog={true} />
            <Features1 />
            <div className="feature-decor">
                <div className="feature-circle1">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`} alt="" />
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="offset-lg-12 col-lg-12">
                        <div className="row">
                            <div className="col-sm-12 mrgn-md-top">
                                <h2 className="title text-center">
                                    <span>  Features of Rise & Nest Unlimited</span>
                                    <Helmet>
                                        <title>Property Management Software Features | Rise & Nest</title>
                                        <meta name="description" content="Explore our features and find out what makes Rise & Nest the best rental property management software and community association management software available." />
                                        <meta name="keywords" content="Account Management, User Management, Property Management, Lease Management, Case Management, Vendor On Boarding, Cloud Property Management Software, landlord management software" />
                                    </Helmet>
                                </h2>
                            </div>

                            <div className="col-12 col-md-6 sm-m-top col-xl-6">
                                <ul className="feature-style">
                                    <li>
                                        <div className="feature-icon">
                                            <i className="fa fa-briefcase fa-2x"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Account Management </h3>
                                        </div>
                                        <div>
                                            <ul className="insideul">
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Dashboard
                                                    <ul className="insideul">
                                                        <li id="SecondLevelSubpoint">
                                                            <i className="fa fa-angle-right" aria-hidden="true" id="greenColor" ></i>
                                                            Owner Dashboard
                                                            <ul className="insideul innersubpoints">
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Overdue Payment
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Payment Outstanding
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Payment Schedule by Property & Tenant
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Recuring Payment
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li id="SecondLevelSubpoint">
                                                            <i className="fa fa-angle-right" aria-hidden="true" id="greenColor"></i>
                                                            Property Manger Dashboard
                                                            <ul className="insideul innersubpoints">
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Overdue Payment by Owner
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Payment Outstanding by Owner
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Profession Bill and Payment
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Recuring Payment
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Utility / Maintenance Payment
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li id="SecondLevelSubpoint">
                                                            <i className="fa fa-angle-right" aria-hidden="true" id="greenColor"></i>
                                                            Tenant Dashboard
                                                            <ul className="insideul innersubpoints">
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Overdue Payment
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Payment Outstanding
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Payment Schedule
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Recuring Payment
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li id="SecondLevelSubpoint">
                                                            <i className="fa fa-angle-right" aria-hidden="true" id="greenColor"></i>
                                                            Professionals Dashboard
                                                            <ul className="insideul innersubpoints">
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Overdue Payment
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Payment Outstanding
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Payment Schedule
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-circle" aria-hidden="true" id="newIconFont" ></i>
                                                                    Recuring Payment
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Invoicing
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Fee Distribution
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Online Payment
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <i className="fa fa-users fa-2x"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>User Management</h3>
                                        </div>
                                        <div>
                                            <ul className="insideul">
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Property Manager
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Owner Activation / Deactivation
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Tenant Activation / Deactivation
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Service Professional / Vendor Activation / Deactivation
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Vendor Activation / Deactivation
                                                </li>
                                            </ul>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-12 col-md-6 sm-m-top col-xl-6">
                                <ul className="feature-style">
                                    <li>
                                        <div className="feature-icon">
                                            <i className="fa fa-building-o  fa-2x"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Property Management</h3>
                                        </div>
                                        <div>
                                            <ul className="insideul">
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Add / Modify Property
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Add / Modify Unit

                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Add / Modify Property Photos

                                                </li>
                                            </ul>

                                        </div>
                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`} alt="icon" />
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Lease Management </h3>
                                        </div>
                                        <div>
                                            <ul className="insideul">
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Move In
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Move Out

                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Tenant Background Screening / Verification
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Contract Printing
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Custom Contract
                                                </li>
                                            </ul>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="feature-icon">

                                            <i className="fa fa-bell-o  fa-2x"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Notifications </h3>
                                        </div>
                                        <div>
                                            <ul className="insideul">
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Payment reminder to Tenant
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Payment confirmation to Property Owner & Tenant
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Move Out Request
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Case resolution & Assignment
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Invoice
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    User Activation / Deactivation
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Property Activation / Deactivation
                                                </li>
                                                <li id="FirstLevelSubpoint">
                                                    <i className="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                    Notify to Owner about Maintenance expenses, Lease Expiration, New Lease
                                                </li>
                                            </ul>

                                        </div>
                                    </li>

                                    <li>
                                        <div className="feature-icon">

                                            <i className="fa fa-cogs fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Case Management</h3>
                                        </div>

                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <i className="fa fa-user fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Vendor On Boarding</h3>
                                        </div>

                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>Reports & Dashboard</h3>
                                        </div>

                                    </li>
                                    <li>
                                        <div className="feature-icon">
                                            <i className="fa fa-user-plus fa-2x" aria-hidden="true"></i>
                                        </div>
                                        <div className="feature-subtitle">
                                            <h3>End Client Access and on boarding</h3>
                                        </div>

                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AllRiseAndNestFeatures;
