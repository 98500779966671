import React, { useEffect } from "react";
import ShareButton from "./ShareButton";

const Footer = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);
  return (
    <div>
      <section className="p-0">
        <div className="container-fluid backgroundfootercolor">
          <div className="bottom-section dataaAbout1  ">
            <div className="row">
              {/* <div className='col-md-4 '>
            
            </div> */}
              <div className='col-md-6 col-md-offset-1 dataBorder '>
                <h1 className="dataaAbout2 margintopfotfooter ">Contact Info</h1>
                <div className='contact-item '>

                  <p className="dataaAbout2 fontweightf">
                    <span>
                      <i className='fa fa-map-marker'></i> Address
                    </span>
                    {' '}
                  </p>
                  <p className="dataaAbout2 fontweightf">
                    riseandnest.com , 310 Gorden Dr. Paramus NJ – 07652 USA
                  </p>

                </div>
                <div className='contact-item'>
                  <p className="dataaAbout2 fontweightf">
                    <span>
                      <i className='fa fa-phone '></i> Phone
                    </span>{' '}
                    +1 201-254-5116
                  </p>
                </div>
                <div className='contact-item'>
                  <p className='dataaAbout2 fontweightf'>
                    <span>
                      <i className='fa fa-envelope-o'></i> Email
                    </span>{' '}
                    info@riseandnest.com
                  </p>
                </div>
              </div>
              <div className='col-md-6 col-md-offset-1 dataBorder '>
                <h1 className="dataaAbout2 margintopfotfooter ">Connect With Us</h1>
                <div className='contact-item '>

                  <p className="dataaAbout2 fontweightf">
                    Follow us on social media account to get updates and information.
                  </p>
                </div>
                <div className='contact-item'>
                  <p className='dataaAbout2 fontweightf'>
                    <ShareButton />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <div className="copyright-section index-footer">
        <p>Copyright © 2021 <a href="https://www.riseandnest.com/" style={{ color: "#777777" }}>Rise & Nest</a> |  <a href="https://www.pletratech.com/" style={{ color: "#777777" }}>Pletratech</a>
        </p>

      </div>

      <div className="tap-top">
        <div>
          <i className="fa fa-angle-double-up"></i>
        </div>
      </div>
      <div id="fb-root"></div>
      {/*Your customer chat code*/}
      <div
        className="fb-customerchat"
        page_id="2123438804574660"
        theme_color="#18e7d3"
        logged_in_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
        logged_out_greeting="Hi! Welcome to PixelStrap Themes  How can we help you?"
      ></div>
    </div>
  );
};

export default Footer;
