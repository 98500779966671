import React, { useEffect, useState } from "react";
import Menu from "./components/Navbar";
import "react-modal-video/scss/modal-video.scss";
import Tilt from "react-tilt";
import About from "./components/About";
import Feature from "./components/Feature";
import ScreenShot from "./components/Screenshot";
import Team from "./components/Team";
import Blog from "./components/Blog";
import Price from "./components/Price";
import Contact from "./components/Contact";
import Subscribe from "./components/Subscribe";
import Footer from "./components/Footer";
import Testimonial from "./components/Testimonial";
import ModalVideo from "react-modal-video";
import FeatureTable from "./components/FeatureTable";
import Modules from "./components/Modules";

const HomeThree = () => {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  /*For changing background image by changing the color*/
  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/home3-slider.png)`
  });

  const color = localStorage.getItem("color");

  useEffect(() => {

    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/home3/home3-slider.png)`
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/Homev3.png)`
      })
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/Homev3.png)`
      })
  }, [color]);

  /* For Open video modal */
  const [isOpen, setIsOpen] = useState(false);

  document.body.classList.remove("landing-page");
  document.body.classList.remove("home-style-two");
  document.body.classList.add("home-style");
  document.body.classList.add("three");
  return (
    <div>
      {/* Navbar Component*/}
      <Menu homePage="home-three" />

      {/* Home Two Section Start */}
      <section id="home" className="home home-three vertical-scrolling" style={bgImg}>
        <div className="container">
          <div className="row">
            <div className="col-md-5 col-sm-12">
              <div className="home-contain">
                <div className="text-white">
                  <div className="contain">
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/images/home2/logo-icon.png`} alt="" /> */}

                    {/* <h1 className="text-white">
                      Rise&nbsp;
                      <span className="f-bold">&</span>
                      &nbsp;Nest
                    </h1> */}
                    <h1 className="text-white home2font">
                      <span className="">Rise</span> <span className="f-bold ">&</span> <span className="">Nest</span>
                    </h1>
                    <p className="slide-cap-desc">
                      Rise & Nest is property management software to manage your rental property online. It is very simple, interactive, userfriendly and powerful application .
                    </p>
                    {/* <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                      <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore" className="store" />
                    </a>
                    <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                      <img
                        className="ml-10 store"
                        src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                        alt="play-store"
                      />
                    </a> */}
                  </div>
                  {/* <div className="play-button">
                    <ModalVideo
                      channel="vimeo"
                      isOpen={isOpen}
                      videoId="54298665"
                      height={600}
                      width={800}
                      onClose={() => setIsOpen(false)}
                    />
                    <a href="javascript" onClick={(e) => { e.preventDefault() }} className="popup-vimeo animated-circle">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/home2/play-button.png`}
                        alt="play-button"
                        onClick={() => setIsOpen(true)}
                        className="img-fluid"
                      />
                    </a>
                  </div> */}

                  <div className="top-margin">
                    <button type="button" class="btn btn-custom theme-color theme-color1" id="demobtn" onClick={() => { window.location.href = "http://app.riseandnest.com/login" }}>Get A Demo</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="home-right">
                <Tilt
                  options={{ perspective: 110, speed: 400, max: 1.2 }}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/Mac111.png`}
                    className="img-fluid"
                    alt="mac"
                  />
                </Tilt>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Two Section End */}

      {/* About Component*/}
      <About />

      {/*Feature Component*/}
      {/* <Feature /> */}

      {/*Feature Table*/}
      <FeatureTable />


      {/*Modules Component*/}
      <Modules />

      {/*ScreenShot Component*/}
      <ScreenShot />

      {/*Team Component*/}
      {/* <Team /> */}

      {/*Blog Component*/}
      {/* <Blog /> */}

      {/*Price Component*/}
      {/* <Price /> */}

      {/*Testimonial Component*/}
      {/* <Testimonial /> */}

      {/*Contact Component*/}
      {/* <Contact /> */}

      {/*Subscription Component*/}
      {/* <Subscribe /> */}

      {/*Footer Component*/}
      <Footer />
    </div>
  );
};

export default HomeThree;
