import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
const About = () => {
  const history = useHistory();
  const [temp, setTemp] = useState(0);



  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);

  const [bgImg, setBgImg] = useState({
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
  });

  const color = localStorage.getItem("color");
  useEffect(() => {

    if (color === "color-1")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`
      });
    else if (color === "color-2")
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/about-bg.png)`
      })
    else
      setBgImg({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/about-bg.png)`
      })
  }, [color]);


  const showContent = () => {
    setTemp(1);
  }

  const showLessContent = () => {
    setTemp(0);
  }


  return (
    <section id="about" className="about" style={bgImg}>
      <div className="about-decor">
        <div className="about-circle1">
          <img src={`${process.env.PUBLIC_URL}/assets/images/team1.png`} alt="team1" />
        </div>
        <div className="about-circle2">
          <img src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`} alt="banner1" />
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-5">
            <div className="about-contain">
              <div>
                <h2 className="title">
                  About The <span>Rise & Nest</span>
                </h2>
                {/* <p className="caption-about">
                  Rise & Nest is rental property management software
                  designed for you. Our software enables you to do
                  more than just manage properties. Rise & Nest
                  is all-in-one software platform to replace all those
                  fragmented tools so accounting, maintainance, application,
                  properties of owner, manager and tenants requests can all
                  live in one place.
                </p> */}
                {/* <div className="row sm-mb">
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon1.png`} alt="easy-to-customized" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Manage Properties</h3>
                        </div>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon3.png`} alt="easy-to-use" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>easy to use</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon2.png`} alt="Awasome-Design" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Manage Contacts</h3>
                        </div>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon4.png`} alt="SEO-Friendly" />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>User Friendly</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="top-margin">
                  <button
                    type="button"
                    className="btn btn-custom theme-color theme-color"
                    onClick={showContent}
                  >
                    view more
                  </button>
                </div> */}
                <div>
                  <p className="caption-about">
                    Rise & Nest is rental property management software designed for you.
                    Our software enables you to do more than just manage properties.
                    Rise & Nest is an all-in-one software platform to replace all those
                    fragmented tools so accounting, maintenance, applications,
                    properties of owners, managers, and tenants requests can all live in one place.
                  </p>
                  <div>
                    {temp === 0 ? (
                      <button
                        type="button"
                        className="btn btn-custom theme-color theme-color"
                        onClick={showContent}
                      >
                        view more
                      </button>
                    ) : (
                      <div>
                        <p className="caption-about">
                          Rise & Nest can handle tenant rental application forms. Rise & Nest offers a lease template and also allows you to develop your own. The leasing procedure is made easier with Rise & Nest. The Rise & Nest maintenance module is meant to track requests, give them to service professionals, and pay the expenses. Rise & Nest allows you to track and handle accounting invoices digitally. Data security is also provided by Rise & Nest.
                        </p>
                        <button
                          type="button"
                          className="btn btn-custom theme-color theme-color"
                          onClick={showLessContent}
                        >
                          View Less
                        </button>
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="col-md-7 d-medium-none">
            <div className="about-right">
              <div className="about-phone">
                <img src={`${process.env.PUBLIC_URL}/assets/images/Mac111.png`} className="img-fluid" alt="aboutus" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
