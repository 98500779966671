import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Collapse } from "reactstrap";
import Menu from "../components/Navbar";
import Footer from "../components/Footer";

const FAQ = ({ title, description, pathSlug, keywords }) => {
  // useEffect(() => {
  //   let timer= setTimeout(function () {
  //     document.querySelector(".loader-wrapper").style = "display: none";
  //   }, 2000);
  //   return () =>{ clearTimeout(timer)}
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [collapse, setCollapse] = useState(1);
  const [isOpen, setIsOpen] = useState(true);

  const toggleMenu = (id) => {
    if (collapse === id) {
      setIsOpen(false);
      setCollapse(null);
    } else {
      setIsOpen(true);
      setCollapse(id);
    }
  }

  return (
    <div>

      {/* Navbar Component*/}
      <Menu />

      {/* Blog Section start */}
      <div className="page-margin">
        {/* breadcrumb start */}
        <div className="breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                <h2 className="title">
                  <span>FAQ</span>
                  <Helmet>
                    <title>Property Management Software FAQ | Rise & Nest</title>
                    <meta name="description" content="Property Management Software FAQ’s – Rise & Nest – landlord management software, web based property management software, rental Management software." data-react-helmet="true" />
                    <meta name="keywords" content="Property Management Software, Rental Property Management Software, Cloud Property Management Software, Rental Property Management System, Best Crm For Property Management, Cloud-based Real Estate Management Software, Landlord Management Software, Lease Management Software, Property Management Portal Software, Tenant Management System, Landlord Management Software" data-react-helmet="true" />
                  </Helmet>
                </h2>
              </div>
              <div className="col-md-6 col-sm-6 col-text-center">
                <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                  <ol className="breadcrumb bg-transparent mb-0">
                    <li className="breadcrumb-item">
                      <a href={`${process.env.PUBLIC_URL}/`}>Home</a>
                    </li>
                    <li className="breadcrumb-item active">
                      <a href="javascript" onClick={(e) => { e.preventDefault() }}>FAQ</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* breadcrumb end */}

        {/* sign in */}
        <section>
          <div className="innerpage-decor">
            <div className="innerpage-circle1">
              <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial2.png`} alt="" />
            </div>
            <div className="innerpage-circle2">
              <img src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`} alt="" />
            </div>
          </div>
          {/* faq in */}
          <div className="faq">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div id="accordion">
                    <div className="card border-theme mb-3 radius-0">
                      <div className="card-header" onClick={() => toggleMenu(1)}>
                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                          Why Rise & Nest ?{" "}
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>

                      <Collapse isOpen={collapse === 1 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            Rise & Nest is property management software that
                            streamlines and simplifies everyday operations
                            for owners, managers, renters, and service professionals.
                            Rise & Nest brings together the power, simplicity,
                            and affordability that thousands of property managers
                            rely on to meet their objectives.
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    <div className="card border-theme mb-3 radius-0">
                      <div className="card-header" onClick={() => toggleMenu(2)}>
                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                          What property types are supported by Rise & Nest ?{" "}
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 2 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            Rise & Nest is new in property management market.
                            Rise & Nest supports Residental property type.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="card border-theme mb-3 radius-0">
                      <div className="card-header" onClick={() => toggleMenu(3)}>
                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                          Is there a minimum number of units required to use Rise & Nest ?
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 3 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            There is no minimum number of units that you must manage.
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    <div className="card border-theme mb-3 radius-0">
                      <div className="card-header" onClick={() => toggleMenu(4)}>
                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                          Are there any setup charges ?
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 4 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            There aren't any startup fees. You don't have
                            to spend anything to start using Rise & Nest.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="card border-theme mb-3 radius-0">
                      <div className="card-header" onClick={() => toggleMenu(5)}>
                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                          What if i need help ?
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 5 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            You also get up to 8 hours of free phone support
                            during weekdays. You can further contact us through
                            mail/ number we provided.
                          </div>
                        </div>
                      </Collapse>
                    </div>

                    <div className="card border-theme mb-3 radius-0">
                      <div className="card-header" onClick={() => toggleMenu(6)}>
                        <a href="javascript" onClick={(e) => { e.preventDefault() }}>
                          Are there any cost for training ?
                          <i className="fa fa-angle-down"></i>
                        </a>
                      </div>
                      <Collapse isOpen={collapse === 6 ? isOpen : false}>
                        <div>
                          <div className="card-body">
                            The training plan that we design is based on your
                            business requirements. In addition to identifying
                            the features that matter most to you—whether it
                            is accounting, leasing, communication, maintenance,
                            and more—we assist you with figuring out how to be
                            productive faster.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* faq up */}
        </section>
        {/* sign up */}

        {/* Footer Section start */}
        {/* <div className="bg-light">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-section">
                  <p>Copyright © 2021 <a href="https://www.riseandnest.com/" style={{ color: "#777777" }}>Rise & Nest</a> |  <a href="https://www.pletratech.com/" style={{ color: "#777777" }}>Pletratech</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Footer Section End */}
        <Footer />
      </div>
      {/* blog Section start */}
    </div>
  );
};

export default FAQ;
