import "./App.css";
import BlogDetails from "./blog/BlogDetails";
import BlogLeftSide from "./blog/BlogLeftSide";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogList from "./blog/BlogList";
import BlogRightSide from "./blog/BlogRightSide";
import BlogRightSidebar from "./blog/BlogRightSidebar";
import DemoApp from "./demo-page/index";
import AllRiseAndNestFeatures from "./components/AllRiseAndNestFeatures";
import ComingSoon from "./Pages/ComingSoon";
import RiseAnsNestLimited from "./components/RiseAnsNestLimited";
import RiseAndNestUnlimited from "./components/RiseAndNestUnlimited";
import FAQ from "./Pages/FAQ";
// import Career from "./components/Career";
import React, { useEffect } from "react";
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import { Route, Switch } from "react-router-dom";
import ColorPicker from "./Services/ColorPicker";
import Features1 from "./components/Features1";
import { BrowserRouter as Router } from 'react-router-dom';
// import history from './history';
import About from "./components/About";
import ContactUs from "./components/ContactUs";
// import { Helmet } from "react-helmet";


function App() {
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => { clearTimeout(timer) }
  }, []);
  localStorage.setItem("color", "color-1");
  return (
    <div className="App">
      <ColorPicker />

      <Switch
      >

        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeThree} />
        <Route exact path={`${process.env.PUBLIC_URL}/#feature`} component={HomeThree} />
        <Route
          path={`${process.env.PUBLIC_URL}/home-one`}
          component={HomeOne}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-two`}
          component={HomeTwo}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-three`}
          component={HomeThree}
        />

        <Route
          path={`${process.env.PUBLIC_URL}/blog-details`}
          component={BlogDetails}
        />
        {/* <Route
          path={`/blog-details/:id`}
          component={BlogDetails}
        /> */}
        <Route
          path={`${process.env.PUBLIC_URL}/blog-list`}
          component={BlogList}
        />
        <Route
          path={`/riseAndNestBlogHomePage`}
          component={BlogList}
        />
        {/* //ContactUs */}
        {/* {Features rouutes  Features1} */}
        <Route
          path={`/riseAndNestFeatures`}
          component={Features1}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/riseAndNestFeatures`}
          component={AllRiseAndNestFeatures}
        />
        {/* //ContactUs */}
        <Route
          path={`/contactus`}
          component={ContactUs}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/riseandNestLimitedPlan`}
          component={RiseAnsNestLimited}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/riseAndNestUnlimitedPlan`}
          component={RiseAndNestUnlimited}
        />
        {/* <Route path={`/About`} component={About} /> */}
        <Route path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
        <Route path={`/FAQ`} component={FAQ} />
        <Route
          path={`${process.env.PUBLIC_URL}/coming-soon`}
          component={ComingSoon}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blogs`}
          component={ComingSoon}
        />
        {/* <Route
          path={`${process.env.PUBLIC_URL}/career`}
          component={Career}
        /> */}

        {/* Template routes  */}
        {/* <Route exact path={`${process.env.PUBLIC_URL}/`} component={DemoApp} />
        <Route
          path={`${process.env.PUBLIC_URL}/home-one`}
          component={HomeOne}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-two`}
          component={HomeTwo}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-three`}
          component={HomeThree}
        />
      
        <Route
          path={`${process.env.PUBLIC_URL}/blog-details`}
          component={BlogDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-list`}
          component={BlogList}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
          component={BlogLeftSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
          component={BlogRightSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-leftside`}
          component={BlogLeftSide}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-rightside`}
          component={BlogRightSide}
        />
        <Route path={`${process.env.PUBLIC_URL}/sign-in`} component={SignIn} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/sign-up`}
          component={SignUp}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forget-password`}
          component={ForgetPwd}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/thank-you`}
          component={ThankYou}
        />
        <Route path={`${process.env.PUBLIC_URL}/review`} component={Review} />
        <Route
          path={`${process.env.PUBLIC_URL}/404`}
          component={PageNotFound}
        />
        <Route path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
        <Route
          path={`${process.env.PUBLIC_URL}/download`}
          component={Download}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/coming-soon`}
          component={ComingSoon}
        />
        <Route component={PageNotFound} /> */}
      </Switch>

    </div>
  );
}

export default App;
