import React, { useEffect } from "react";
import Features1 from "./Features1";
import Menu from "./Navbar";
import Footer from "./Footer";

const RiseAnsNestLimited = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Menu blog={true} />
            <Features1 />
            <Footer />
        </>
    );
};

export default RiseAnsNestLimited;
