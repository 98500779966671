import React, { Component } from 'react';
import {
    FacebookShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,
    HatenaShareCount,
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookMessengerIcon,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,
    WorkplaceShareButton,
    LineShareButton,
    WeiboShareButton,
    PocketShareButton,
    InstapaperShareButton,
    HatenaShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
    WorkplaceIcon,
    LineIcon,
    PocketIcon,
    InstapaperIcon,
    WeiboIcon,
    HatenaIcon,
    instagramIcon
} from "react-share";

import './Demo.css';
import Instagram from 'react-share-icons/lib/Instagram';
// import { SocialMediaIconsReact } from 'social-media-icons-react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';


class ShareButton extends Component {
    render() {
        const shareUrl = 'http://github.com';
        const title = 'GitHub';

        return (
            <div className="Demo__container marginbottom">
                <div className="Demo__some-network">
                    <a href="https://www.facebook.com/riseandnest/" target="_blank" rel="noopener noreferrer">
                        <FaFacebook style={{ borderColor: 'rgba(0,0,0,0.25)', color: 'rgba(255,255,255,1)' }} size={32} />
                    </a>
                </div>

                <div className="Demo__some-network">
                    <a href="https://twitter.com/Riseandnest" target="_blank" rel="noopener noreferrer">
                        <FaTwitter style={{ borderColor: 'rgba(0,0,0,0.25)', color: 'rgba(255,255,255,1)', fontSize: '5px' }} size={32} />
                    </a>
                    <div className="Demo__some-network__share-count">&nbsp;</div>
                </div>

                <div className="Demo__some-network">
                    <a href="https://www.linkedin.com/company/riseandnest/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin style={{ borderColor: 'rgba(0,0,0,0.25)', color: 'rgba(255,255,255,1)' }} size={32} />
                    </a>
                </div>

                <div className="Demo__some-network">
                    <a href="https://www.instagram.com/riseandnest/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram style={{ borderColor: 'rgba(0,0,0,0.25)', color: 'rgba(255,255,255,1)' }} size={32} />
                    </a>
                </div>

            </div>
        );
    }
}

export default ShareButton;
