import React, { useEffect } from "react";
import PriceUnlimited from "./PriceUnlimited";
import Menu from "./Navbar";
import Footer from "./Footer";

const RiseAndNestUnlimited = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Menu blog={true} />
            <PriceUnlimited />
            <Footer />
        </>
    );
};

export default RiseAndNestUnlimited;
