import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // const HandleScroll = () => {
  //   if (window.scrollY >= 60) {
  //     document.getElementById("Navbar")?.classList.add("darkHeader");
  //   } else {
  //     document.getElementById("Navbar")?.classList.remove("darkHeader");
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", HandleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", HandleScroll);
  //   };
  // }, []);

  useEffect(() => {
    // let timer = setTimeout(function () {
    //   document.querySelector(".loader-wrapper").style = "display: none";
    // }, 2000);
    // return () => { clearTimeout(timer) }
  }, []);

  return (
    <>
      <Navbar
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top darkHeader"
      >
        <div id="navbar-main" className="container ">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img src={`${process.env.PUBLIC_URL}/assets/images/Riseandnest_Logo.png`} alt="logo" className="imgwidth" />
          </NavbarBrand>
          <NavbarToggler className="navbar-toggler" onClick={toggle} />
          <Collapse
            id="navbarSupportedContent "
            className="default-nav"
            isOpen={isOpen}
            navbar
          >
            <Nav className="ml-auto " navbar>
              <NavItem className="menu-item">
                <NavLink className="nav-link" href={`${process.env.PUBLIC_URL}/#feature`}>features</NavLink>
              </NavItem>
              <NavItem className="menu-item">
                <NavLink className="nav-link" href={`${process.env.PUBLIC_URL}/#feature`}>pricing</NavLink>
              </NavItem>
              <NavItem className="menu-item">
                <Link
                  className="nav-link"
                  to={`${process.env.PUBLIC_URL}/FAQ`}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  FAQ
                </Link>
              </NavItem>

              <NavItem className="menu-item">
                <Link
                  className="nav-link"
                  to={`${process.env.PUBLIC_URL}/blogs`}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  Blog
                </Link>
              </NavItem>
              <NavItem className="menu-item">
                <NavLink className="nav-link" href={`${process.env.PUBLIC_URL}/#about`}>About</NavLink>
              </NavItem>
              <NavItem className="menu-item">
                <Link
                  className="nav-link"
                  to="/contactus"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={toggle}
                >
                  Contact
                </Link>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="btn btn-custom theme-color margin-left demoCss " id="marginleftforweb1"> Get Started</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink className="nav-link fontAndColor  " href="http://app.riseandnest.com/login" onClick={toggle}>Get Free Demo</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink className="nav-link  fontAndColor " href="http://app.riseandnest.com/login" onClick={toggle}>Login</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink className="nav-link  fontAndColor" href="http://app.riseandnest.com/login" onClick={toggle}>Register</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>


            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
};

export default Menu;
