import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Menu from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const PriceUnlimited = () => {
    // useEffect(() => {
    //   let timer= setTimeout(function () {
    //     document.querySelector(".loader-wrapper").style = "display: none";
    //   }, 2000);
    //   return () =>{ clearTimeout(timer)}
    // }, []);
    const [bgImg, setBgImg] = useState({
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
    });

    const color = localStorage.getItem("color");
    useEffect(() => {

        if (color === "color-1")
            setBgImg({
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`
            });
        else if (color === "color-2")
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-2/feature-bg1.png)` });
        else
            setBgImg({ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/color/color-3/feature-bg1.png)` });
    }, [color]);

    return (

        <>
            <div className="modifyingsectioncss">
                {/* <Menu blog={true}/> */}
                <section id="feature" className="feature" >
                    <div className="feature-decor">
                        <div className="feature-circle1">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`} alt="" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {/* <div className="feature-phone">
                <img src={`${process.env.PUBLIC_URL}/assets/images/222.png`} className="img-fluid" alt="" />
              </div> */}
                            <div className="offset-lg-12 col-lg-12">
                                <div className="row">
                                    <div className="col-sm-12 mrgn-md-top margintopforfeatures">
                                        <h2 className="title text-center">
                                            <span>  Features of Rise & Nest Unlimited</span>
                                            <Helmet>
                                                <title>Best Rental Property Management Software Features | Rise & Nest</title>

                                                <meta name="description" content="Explore our features and find out what makes Rise & Nest the Best Rental Property Management Software and community association management software available." />

                                                <meta name="keywords" content="Property Management Software, Rental Property Management Software, Cloud Property Management Software, Rental Property Management System, Best Crm For Property Management, Cloud-based Real Estate Management Software, Landlord Management Software, Lease Management Software, Property Management Portal Software, Tenant Management System, Landlord Management Software" />
                                            </Helmet>
                                        </h2>
                                    </div>

                                    <div className="col-12 col-md-6 sm-m-top col-xl-6">
                                        <ul className="feature-style">
                                            <li>
                                                <div className="feature-icon">
                                                    <i class="fa fa-briefcase fa-2x"></i>
                                                    {/* fa-cogs */}
                                                </div>
                                                <div className="feature-subtitle  ">
                                                    <h3>Account Management </h3>
                                                </div>
                                                <div className="">
                                                    {/* <p>
                           .{" "}
                          </p> */}
                                                    <ul className="insideul">
                                                        <li id="FirstLevelSubpoint" className="marginbottomsize">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Dashboard
                                                            <ul className="insideul">
                                                                <li id="SecondLevelSubpoint">
                                                                    <i class="fa fa-angle-right" aria-hidden="true" id="greenColor"  ></i>
                                                                    Owner Dashboard

                                                                    <ul className="insideul innersubpoints">
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Overdue Payment

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Payment Outstanding

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Payment Schedule by Property & Tenant

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Recurring Payment

                                                                        </li>
                                                                    </ul>


                                                                </li>
                                                                <li id="SecondLevelSubpoint">
                                                                    <i class="fa fa-angle-right" aria-hidden="true" id="greenColor"></i>
                                                                    Property Manager Dashboard
                                                                    <ul className="insideul innersubpoints">
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Overdue Payment by Owner

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Payment Outstanding by Owner

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Profession Bill and Payment

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Recurring Payment

                                                                        </li>


                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Utility / Maintenance Payment

                                                                        </li>

                                                                    </ul>
                                                                </li>
                                                                <li id="SecondLevelSubpoint">
                                                                    <i class="fa fa-angle-right" aria-hidden="true" id="greenColor"></i>
                                                                    Tenant Dashboard
                                                                    <ul className="insideul innersubpoints">
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Overdue Payment

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Payment Outstanding

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Payment Schedule

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Recurring Payment

                                                                        </li>

                                                                    </ul>
                                                                </li>
                                                                <li id="SecondLevelSubpoint">
                                                                    <i class="fa fa-angle-right" aria-hidden="true" id="greenColor"></i>
                                                                    Professionals Dashboard
                                                                    <ul className="insideul innersubpoints">
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Overdue Payment

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Payment Outstanding

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Payment Schedule

                                                                        </li>
                                                                        <li>
                                                                            <i class="fa fa-circle" aria-hidden="true" id="newIconFont"  ></i>
                                                                            Recurring Payment

                                                                        </li>

                                                                    </ul>

                                                                </li>

                                                            </ul>



                                                        </li>

                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Invoicing
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Fee Distribution
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Online Payment
                                                        </li>

                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="feature-icon ">
                                                    {/* fa-users */}
                                                    <i class="fa fa-users fa-2x"></i>
                                                </div>
                                                <div className="">
                                                    <div className="feature-subtitle ">
                                                        <h3>User Management</h3>
                                                    </div>
                                                    <div>
                                                        <ul className="insideul">
                                                            <li id="FirstLevelSubpoint">
                                                                <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                                Property Manager
                                                            </li>
                                                            <li id="FirstLevelSubpoint">
                                                                <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                                Owner Activation / Deactivation
                                                            </li>

                                                            <li id="FirstLevelSubpoint">
                                                                <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                                Tenant Activation / Deactivation
                                                            </li>
                                                            <li id="FirstLevelSubpoint">
                                                                <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                                Service Professional / Vendor Activation / Deactivation
                                                            </li>
                                                            <li id="FirstLevelSubpoint" >
                                                                <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                                Vendor Activation / Deactivation
                                                            </li>


                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>



                                        </ul>
                                    </div>
                                    {/* second column content */}
                                    <div className="col-12 col-md-6 sm-m-top col-xl-6">
                                        <ul className="feature-style">
                                            <li>
                                                <div className="feature-icon">

                                                    <i class="fa fa-building-o  fa-2x"></i>
                                                </div>
                                                <div className="feature-subtitle">
                                                    <h3>Property Management</h3>
                                                </div>
                                                <div>
                                                    <ul className="insideul">
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Add / Modify Property
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Add / Modify Unit

                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Add / Modify Property Photos

                                                        </li>
                                                    </ul>

                                                </div>
                                            </li>
                                            <li>
                                                <div className="feature-icon">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`} alt="icon" />
                                                </div>
                                                <div className="feature-subtitle">
                                                    <h3>Lease Management </h3>
                                                </div>
                                                <div>
                                                    <ul className="insideul">
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Move In

                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Move Out

                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Tenant Background Screening / Verification

                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Contract Printing

                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Custom Contract

                                                        </li>
                                                    </ul>

                                                </div>
                                            </li>

                                            <li>

                                                <div className="feature-icon">

                                                    <i class="fa fa-bell-o  fa-2x"></i>

                                                </div>
                                                <div className="feature-subtitle">
                                                    <h3>Notifications </h3>
                                                </div>
                                                <div>
                                                    <ul className="insideul">
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Payment reminder to Tenant
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Payment confirmation to Property Owner & Tenant
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Move Out Request
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Case resolution & Assignment
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Invoice
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            User Activation / Deactivation
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Property Activation / Deactivation
                                                        </li>
                                                        <li id="FirstLevelSubpoint">
                                                            <i class="fa fa-check-circle" aria-hidden="true" id="greenColor" ></i>
                                                            Notify to Owner about Maintenance expenses, Lease Expiration, New Lease
                                                        </li>
                                                    </ul>

                                                </div>
                                            </li>

                                            {/* rest of the list items */}
                                            <li>
                                                <div className="feature-icon">
                                                    <i class="fa fa-cogs fa-2x" aria-hidden="true"></i>
                                                </div>
                                                <div className="feature-subtitle">
                                                    <h3>Case Management</h3>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="feature-icon">
                                                    <i class="fa fa-user fa-2x" aria-hidden="true"></i>
                                                </div>
                                                <div className="feature-subtitle">
                                                    <h3>Vendor On Boarding</h3>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="feature-icon">
                                                    <i class="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                                                </div>
                                                <div className="feature-subtitle">
                                                    <h3>Reports & Dashboard</h3>
                                                </div>

                                            </li>
                                            <li>
                                                <div className="feature-icon">
                                                    <i class="fa fa-user-plus fa-2x" aria-hidden="true"></i>
                                                </div>
                                                <div className="feature-subtitle">
                                                    <h3>End Client Access and on boarding</h3>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default PriceUnlimited;

